/* Main Header */

.header {
    width: 100%;
    height: 80px;
    
    position: absolute;

    display: flex;
    justify-content: space-between;

}

/* Logo */

.logoContainer {
    width: 40%;
    height: 80px;
}

.logo {
    width: 200px;
    height: 90px;

    margin-left: 50px;

    background-color: #0f499d;
    /* box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.35); */

    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    width: 100%;
}

/* Navbar */

.navbar {
    width: 60%;
    height: 60px;

    background-color: #0f499d;
}

.menu {
    height: 100%;

    list-style-type: none;

    display: flex;
    align-items: center;
}

.menu li {
    height: 100%;

    padding-left: 20px;
    padding-right: 20px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}

.menu li:hover {
    background-color: #0f55b7;
}

.navbar p {
    font-family: 'Jost';
    font-size: 18px;

    color: white;

    letter-spacing: 2px;
    /* text-decoration: underline; */
}

.mobileNavButton {
    width: 60px;
    height: 60px;

    cursor: pointer;
    background-color: #0f499d;
    
    position: absolute;
    z-index: 3;

    display: none;
    justify-content: center;
    align-items: center;
}

.mobileMenu {
    width: 100%;
    height: 100vh;

    padding-top: 20px;
    
    left: 0;

    background-color: white;
    z-index: 2;
    
    position: absolute;
    list-style-type: none;

    display: none;
    flex-direction: column;
    align-items: center;
}

.mobileMenu li {
    width: 100%;

    cursor: pointer;

    padding-top: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileMenu p {
    color: black;

    font-size: 20px !important;
}

.mobileNavButton img {
    width: 40px;
}

.resources {
    display: flex;
    flex-direction: column;
}

.resources .rTitle {
    margin-bottom: 20px;
}

/* Responsive Design */

@media only screen and (max-width: 1850px) {

    .navbar p {
        font-size: 15px;
    }

    .flexBar {
        display: none !important;
    }

}

@media only screen and (max-width: 1590px) {

    .navbar p {
        font-size: 14px;
    }

}

@media only screen and (max-width: 1525px) {

    .navbar p {
        font-size: 12.5px;
    }

}

@media only screen and (max-width: 1435px) {

    .navbar p {
        font-size: 12px;
    }
    
}

@media only screen and (max-width: 1400px) {

    .navbar {
        width: 60px;
    }

    .menu {
        display: none;
    }

    .mobileNavButton {
        display: flex;
    }

}