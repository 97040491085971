.homeContainer {
    width: 100%;
}

/* Hero Section */

.heroContainer {
    width: 100%;
    height: 100vh;

    display: flex;
}

.heroImage {
    width: 60%;

    background: url('../../assets/heroimage.jpg');
    background-size: cover;
    background-position: center;

}

.heroTitleContainer {
    width: 40%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heroTitleCard {
    width: 100%;
    height: 500px;

    box-sizing: border-box;

    padding-right: 80px;
    margin-top: 25%;
}

.heroTitle {
    font-size: 3.6vw;
    text-align: right;
    font-family: 'Georgia';
}

.heroUnderline {
    text-decoration: underline;
    text-decoration-color: #e62528;
}

.heroAccentBar {
    width: 220px;
    height: 15px;

    float: right;

    background-color: black;

    margin-top: 25px;
    margin-bottom: 35px;
}

.heroButtons {
    width: 100%;
    height: 60px;

    display: flex;
    flex-direction: row-reverse;
    gap: 50px;
}

.heroButtonPrimary {
    width: 200px;
    height: 60px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #e62528;
    color: white;

    font-family: 'Jost';
    font-size: 17px;
}

.heroButtonPrimary:hover {
    background-color: #f8393c;
}

.heroButtonSecondary {
    width: 200px;
    height: 60px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;

    border: solid black 4px;

    font-family: 'Jost';
    font-size: 17px;
}

.heroButtonSecondary:hover {
    background-color: black;
    color: white;
}

@media only screen and (max-width: 1500px) {

    .heroContainer {
        height: 90vh;
    }

    .heroTitle {
        font-size: 3.2vw;
    }

    .heroButtons .heroButtonPrimary {
        width: 190px;
        height: 50px;
    }

    .heroButtons .heroButtonSecondary {
        width: 190px;
        height: 50px;
    }

}

@media only screen and (max-width: 1400px) {

    .heroTitleCard {
        padding-right: 50px;
    }


}

@media only screen and (max-width: 1300px) {

    .heroTitle {
        font-size: 5vw;
    }

    .heroTitleCard {
        margin-top: 17%;
    }

    .heroTitleContainer {
        width: 60%;
    }

    .heroImage {
        width: 40%;
    }

    .heroButtons .heroButtonPrimary {
        width: 200px;
        height: 60px;
    }

    .heroButtons .heroButtonSecondary {
        width: 200px;
        height: 60px;
    }

}

@media only screen and (max-width: 940px) {

    .heroTitleContainer {
        width: 70%;
    }

    .heroImage {
        width: 30%;
    }

    .heroTitleCard {
        margin-top: 20%;
    }

}

@media only screen and (max-width: 940px) {

    .heroContainer {
        flex-direction: column-reverse;
        height: fit-content;
    }

    .heroTitleContainer {
        width: 100%;
        height: 500px;
    }

    .heroImage {
        height: 200px;
        width: 100%;
    }

    .heroTitleCard {
        margin-top: 5%;
    }

    .heroTitle {
        font-size: 40px;
    }

}

@media only screen and (max-width: 540px) {

    .heroButtons .heroButtonPrimary {
        width: 160px;
        height: 60px;
    }

    .heroButtons .heroButtonSecondary {
        width: 160px;
        height: 60px;
    }

}

@media only screen and (max-width: 460px) {

    .heroTitleCard {
        padding-right: 9%;
    }

}

@media only screen and (max-width: 440px) {

    .heroButtons .heroButtonPrimary {
        width: 120px;
        height: 60px;
    }

    .heroButtons .heroButtonPrimary p {
        font-size: 15px;
    }

    .heroButtons .heroButtonSecondary {
        width: 120px;
        height: 60px;
    }

    .heroButtons .heroButtonSecondary p {
        font-size: 15px;
    }

}

@media only screen and (max-width: 420px) {

    .heroTitle {
        font-size: 36px;
    }

}

/* Hero Actions */

.actions {
    width: 100%;

    margin-top: 50px;
    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;
    align-items: end;
}

.downArrow {
    margin-left: 15px;

    cursor: pointer;
    
    animation: moveUpDown 2s ease-in-out infinite;
}

@keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
}

.downArrow img {
    height: 60px;
}

.badges {
    display: flex;
    align-items: end;
}

.badge {
    margin-right: 20px;
    
}

/* About */

.aboutContainer {
    width: 100%;
    height: fit-content;

    background-image: url('../../assets/map-background.png');

    background-color: #0f499d;
}

.textBackground {
    width: 100%;

    padding-bottom: 40px;

    background-image: url('../../assets/text-about-background.png');
    background-position: top;
    background-size: cover;
}

.aboutContent {
    padding-top: 40px;
    padding-left: 30px;
}

.aboutContent p {
    font-family: 'Jost';
    color: white;

    font-size: 25px;
}

.aboutInfo {
    width: 60%;

    margin-top: 30px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.contactButton {
    width: 200px;
    height: 60px;

    margin-top: 15px;

    cursor: pointer;

    /* position: absolute;
    z-index: 5; */

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;

    border: solid white 4px;

    font-family: 'Jost';
}

.contactButton:hover {
    background-color: white;
}

.contactButton:hover p {
    color: black;
}

.contactButton p {
    font-size: 20px;
}

@media only screen and (max-width: 1810px) {

    .aboutInfo {
        display: block;
    }

}

@media only screen and (max-width: 1330px) {

    .aboutContent p {
        font-size: 20px;
    }

}

@media only screen and (max-width: 760px) {

    .aboutContent p {
        font-size: 16px;
    }

}

/* Testimonials */

.testimonialsContainer {
    height: 70vh;

    background-color: #f4f4f4;

    display: flex;
}

.testimonialsGrid {
    width: 50%;

    box-sizing: border-box;

    background-color: black;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.testimonialOne {
    grid-column: 3 / 5;
    grid-row: 1 / 3;

    background: url('../../assets/KW.jpg');
    background-size:  cover;
    background-position: center;

    display: flex;
    align-items: flex-end;

    box-shadow: 0px 4px 4px 0px #00000040, inset 0 0 0 1000px rgba(0,0,0,.4);
    
}

.testimonialOne:hover .testimonialOneTitle {
    display: none;
}

.testimonialOne:hover .testimonialOneView {
    display: block;
}

.testimonialOneView {
    width: 100%;
    height: 100%;

    display: none;

    padding-left: 15px;
    padding-top: 5px;
    padding-right: 10px;

    background-color: rgba(255, 255, 255, 0.7);

    font-family: 'Jost';
    z-index: 2;
}

.testimonialOneHeader {
    font-size: 40px;
}

.testimonialOneContent {
    font-size: 18px;
}

.closeButton {
    width: 150px;
    height: 50px;
    
    font-size: 20px;
    margin-top: 15px;
    
    cursor: pointer;

    position: absolute;
    z-index: 5;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;

    border: solid rgb(0, 0, 0) 4px;

    font-family: 'Jost';
}

.closeButton:hover {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}

.testimonialOne p {

    padding-left: 10px;

    font-family: Jost;
    font-size: 40px;
    color: white;
}

.testimonialTwo {

    background: url('../../assets/Steven.jpg');
    background-size:  cover;
    background-position: center;

    box-shadow: 0px 4px 4px 0px #00000040, inset 0 0 0 1000px rgba(0,0,0,.4);

    box-sizing: border-box;

    display: flex;
    align-items: flex-end;

    grid-column: 1 / 3;
}

.testimonialTwo:hover .testimonialTwoTitle {
    display: none;
}

.testimonialTwo:hover .testimonialTwoView {
    display: block;
}

.testimonialTwoView {
    width: 100%;
    height: 100%;

    display: none;

    padding-left: 15px;
    padding-top: 5px;
    padding-right: 10px;

    background-color: rgba(255, 255, 255, 0.7);

    font-family: 'Jost';
    z-index: 2;
}

.testimonialTwoHeader {
    font-size: 40px;
}

.testimonialTwoContent {
    font-size: 18px;
}

.testimonialTwo p {

    padding-left: 10px;

    font-family: Jost;
    font-size: 40px;
    color: white;
}

.testimonialThree {

    background: url('../../assets/Thomas.jpg');
    background-size: cover;

    box-shadow: 0px 4px 4px 0px #00000040, inset 0 0 0 1000px rgba(0,0,0,.4);

    display: flex;
    align-items: flex-end;

    grid-column: 1 / 3;
}

.testimonialThree:hover .testimonialThreeTitle {
    display: none;
}

.testimonialThree:hover .testimonialThreeView {
    display: block;
}


.testimonialThreeView {
    width: 100%;
    height: 100%;

    display: none;

    padding-left: 15px;
    padding-top: 5px;
    padding-right: 10px;

    background-color: rgba(255, 255, 255, 0.7);

    font-family: 'Jost';
    z-index: 2;
}

.testimonialThreeHeader {
    font-size: 40px;
}

.testimonialThreeContent {
    font-size: 18px;
}

.testimonialThree p {

    padding-left: 10px;

    font-family: Jost;
    font-size: 40px;
    color: white;
}

.testimonialContent {
    
    width: 50%;

    display: flex;
    flex-direction: column;

}

.testimonialContent .content {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

}

.tcHeader {
    padding-top: 50px;

    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 70px;
}

.tcSubHeading {
    font-family: Jost;
    font-size: 25px;
}

.tcAccentImage {

    width: 50%;
    height: 50%;

    margin-top: 30px;

    background: url('../../assets/QuotationMark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.tooltip {
    font-family: Jost;
    font-size: 20px;

    margin-left: 20px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1650px) {

    .tcHeader {
        font-size: 60px;
    }

}

@media only screen and (max-width: 1430px) {

    .tcHeader {
        font-size: 50px;
    }
    
}

@media only screen and (max-width: 1190px) {

    .tcHeader {
        font-size: 40px;
    }

    .tcSubHeading {
        font-size: 20px;
    }

    .testimonialsContainer {
        height: 120vh;
        flex-direction: column;
    }

    .testimonialContent {
        width: 100%;
        height: 40%;
    }

    .testimonialsGrid {
        width: 100%;
        height: 60%;
    }

    .tcHeader {
        font-size: 60px;
    }

    .tcAccentImage {
        width: 100px;
        height: 100px;
    }

}

@media only screen and (max-width: 700px) {

    .tcHeader {
        font-size: 50px;
    }

}

@media only screen and (max-width: 624px) {

    .testimonialOneContent {
        font-size: 15px;
    }

}

@media only screen and (max-width: 624px) {

    .tcHeader {
        font-size: 40px;
    }

}

@media only screen and (max-width: 460px) {

    .testimonialsGrid {
        height: 800px;
    }

    .testimonialOne {
        grid-column: 1 / 5;
    }

    .testimonialTwo {
        grid-column: 1 / 5;
    }

    .testimonialThree {
        grid-column: 1 / 5;
    }

}

@media only screen and (max-width: 450px) {

    .tcHeader {
        font-size: 35px;
    }


}

@media only screen and (max-width: 430px) {

    .tcHeader {
        font-size: 30px;
    }

}

@media only screen and (max-width: 400px) {

    .tcHeader {
        font-size: 30px;
    }

    .tcSubHeading {
        font-size: 18px;
    }

}

/* Our Team */

.teamSection {
    width: 100%;
}

.teamSection .container {
    display: flex;

    padding-top: 2%;
    padding-bottom: 4%;

    justify-content: center;
    align-items: center;
}

.teamSection .row {
    width: fit-content;

    justify-content: space-evenly;

    display: flex;
    flex-wrap: wrap;
    gap: 80px;
}

.teamHeader {
    margin-bottom: 50px;

    font-family: Georgia;
    font-size: 50px;
}

.teamAccentBar {
    width: 220px;
    height: 15px;

    background-color: black;

    margin-top: 10px;
}

.teamCol .teamImage {
    width: 250px;
    height: 250px;
    background-color: black;
}

.teamCol .name {

    margin-top: 10px;

    font-family: Jost;
    font-size: 25px;
}

.teamCol .title {
    font-family: Jost;
}

@media only screen and (max-width: 1700px) {

    .teamCol .teamImage {
        width: 200px;
        height: 200px;
    }

}

@media only screen and (max-width: 1450px) {

    .teamSection .container .content {
        width: 80%;
    }

}

@media only screen and (max-width: 600px) {

    .teamHeader {
        font-size: 40px;
        text-align: center;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}

@media only screen and (max-width: 418px) {

    .teamHeader {
        font-size: 40px;
        text-align: center;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}

/* Surplus Section */

.surplusSection {
    width: 100%;

    background: url(../../assets/surplusBackground.png);
    background-size: cover;
}

.surplusSection .container {

    padding: 2% 10% 1% 1%;

    display: flex;
    justify-content: space-between;

}

.surplusSection .container .details {
    color: white;

    font-family: Jost;
    font-size: 20px;
}

@media only screen and (max-width: 1700px) {

    .surplusSection .container .details {
        color: white;
    
        font-family: Jost;
        font-size: 18px;
    }

}

@media only screen and (max-width: 1500px) {

    .heroText {
        width: 40%;
    }

    .surplusSection .container .details {
        color: white;
    
        font-family: Jost;
        font-size: 16px;
    }
    
}

@media only screen and (max-width: 1200px) {

    .surplusSection .container .details {
        color: white;
    
        font-family: Jost;
        font-size: 15px;
    }

    .surplusSection .contactButton {
        width: 190px;
        height: 50px;
    }

}

@media only screen and (max-width: 1050px) {

    .surplusSection {
        height: 64vh;
    }

    .surplusSection .container {
        flex-direction: column;
        gap: 25px;
        justify-content: center;
        padding-right: 2%;
    }

    .surplusSection .container .details {
        text-align: right;
    }
    
    .surplusSection .contactButton {
        float: right;
    }

}

@media only screen and (max-width: 750px) {

    .surplusSection {
        height: 56vh;
    }



}

/* Contact Section */

.contactSection {
    width: 100%;
    height: 70vh;

    display: flex;
}

.contactSection .contactMap {
    width: 45%;

    display: flex;
    flex-direction: column;
}

.contactSection .contactMap .map {
    flex-grow: 1;
    background-color: bisque;
}

.contactSection .contactMap .footer {
    height: 50px;

    padding: 0% 2% 0% 2%;

    background-color: #323232;

    font-family: Jost;
    color: white;

    display: flex;
    justify-content: space-between;
    align-items: center;
}


.contactSection .contactForm {
    width: 55%;
    background-color: #0f499d;

    padding: 5%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.formRow {
    width: 100%;
    display: flex;
}

.contactSection .contactForm .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactSection .contactForm .container form {
    width: 600px;
}

.contactSection .contactForm .container  label {
    font-family: Jost;
    color:  white;
    padding: 10px 0px 5px 0px;
}

.contactHeader {
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: white;
    font-size: 60px;
}

.contactInfo {
    text-align: center;
    color: white;
    font-family: Jost;
    font-size: 20px;
}

.formInput {
    padding: 0px 5px 0px 5px;

    display: flex;
    flex-direction: column;
}

.formInput input {
    padding: 5px;
}

.formInput textarea {
    padding: 5px;
}

.contactSubmit {
    width: 100%;
    height: 20px;

    font-size: 20px;
    font-family: Jost;
    background-color: #e62528;
    color: white;

    margin-top: 20px;

    padding: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1400px) {

    .contactHeader {
        font-size: 40px;
    }

    .contactInfo {
        font-size: 18px;
    }

}

@media only screen and (max-width: 1200px) {

    .contactSection .contactForm .container form {
        width: 450px;
    }

}

@media only screen and (max-width: 950px) {

    .contactSection {
        flex-direction: column-reverse;
        height: fit-content;
    }

    .contactSection .contactForm  {
        width: 100%;
    }

    .contactSection .contactForm .container form {
        width: 500px;
    }

    .contactSection .contactMap {
        width: 100%;
        height: 50vh;
    }

}

@media only screen and (max-width: 540px) {

    .contactSection .contactForm .container form {
        width: 400px;
    }


}

@media only screen and (max-width: 430px) {

    .contactSection .contactForm .container form {
        width: 300px;
        padding-bottom: 10px;
    }

    .formRow {
        flex-direction: column;
    }

    .formRow .formInput {
        width: 100% !important;
    }

}