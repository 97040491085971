@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;

    scroll-behavior: smooth;
}

/* Font Styling */

@font-face {
    font-family: 'Jost';
    src: url('../fonts/Jost.ttf');
}

@font-face {
    font-family: 'Georgia';
    src: url('../fonts/Georgia.ttf');
}



