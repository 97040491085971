.homeContainer {
    width: 100%;
}

/* Hero Section */

.heroContainer {
    width: 100%;
    height: 55vh;

    display: flex;
}

.heroImage {
    width: 60%;

    background: url('../../assets/foreclosure.jpg');
    background-size: cover;
    background-position: top;

}

.heroTitleContainer {
    width: 40%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heroTitleCard {
    width: 100%;
    height: 200px;

    box-sizing: border-box;

    padding-right: 80px;
    padding-left: 20px;
    margin-top: 15%;
}

.heroTitle {
    font-size: 2.5vw;
    text-align: right;
    font-family: 'Georgia';
}

.heroSubTitle {
    float: right;

    margin-left: 20px;
    margin-bottom: 20px;

    font-family: Jost;
    font-size: 25px;
    text-align: right;
}

.heroUnderline {
    text-decoration: underline;
    text-decoration-color: #e62528;
}

.heroAccentBar {
    width: 220px;
    height: 15px;

    float: right;

    background-color: black;

    margin-top: 25px;
    margin-bottom: 35px;
}

.heroButtons {
    width: 100%;
    height: 60px;

    display: flex;
    flex-direction: row-reverse;
    gap: 50px;
}

.heroButtonPrimary {
    width: 200px;
    height: 60px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #e62528;
    color: white;

    font-family: 'Jost';
    font-size: 17px;
}

.heroButtonPrimary:hover {
    background-color: #f8393c;
}

.heroButtonSecondary {
    width: 200px;
    height: 60px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;

    border: solid black 4px;

    font-family: 'Jost';
    font-size: 17px;
}

.heroButtonSecondary:hover {
    background-color: black;
    color: white;
}

@media only screen and (max-width: 1500px) {

    .heroTitle {
        font-size: 3.2vw;
    }

    .heroButtons .heroButtonPrimary {
        width: 190px;
        height: 50px;
    }

    .heroButtons .heroButtonSecondary {
        width: 190px;
        height: 50px;
    }

}

@media only screen and (max-width: 1400px) {

    .heroTitleCard {
        padding-right: 50px;
    }


}

@media only screen and (max-width: 1300px) {

    .heroTitle {
        font-size: 5vw;
    }

    .heroTitleCard {
        margin-top: 17%;
    }

    .heroTitleContainer {
        width: 60%;
    }

    .heroImage {
        width: 40%;
    }

    .heroButtons .heroButtonPrimary {
        width: 200px;
        height: 60px;
    }

    .heroButtons .heroButtonSecondary {
        width: 200px;
        height: 60px;
    }

}

@media only screen and (max-width: 940px) {

    .heroTitleContainer {
        width: 70%;
    }

    .heroImage {
        width: 30%;
    }

    .heroTitleCard {
        margin-top: 20%;
    }

}

@media only screen and (max-width: 940px) {

    .heroContainer {
        flex-direction: column-reverse;
        height: fit-content;
    }

    .heroTitleContainer {
        width: 100%;
        height: 200px;
    }

    .heroImage {
        height: 200px;
        width: 100%;
    }

    .heroTitleCard {
        margin-top: 5%;
    }

    .heroTitle {
        font-size: 40px;
    }

    .heroAccentBar {
        display: none;
    }

}

@media only screen and (max-width: 800px) {

    .heroTitle {
        font-size: 30px;
    }

    .heroSubTitle {
        font-size: 20px;
    }

    .heroTitleCard {
        height: fit-content;
        padding-bottom: 20px;
    }
    
    .heroTitleContainer {
        height: fit-content;
    }

}

@media only screen and (max-width: 540px) {

    .heroButtons .heroButtonPrimary {
        width: 160px;
        height: 60px;
    }

    .heroButtons .heroButtonSecondary {
        width: 160px;
        height: 60px;
    }

}

@media only screen and (max-width: 460px) {

    .heroTitleCard {
        padding-right: 9%;
    }

}

@media only screen and (max-width: 440px) {

    .heroButtons .heroButtonPrimary {
        width: 120px;
        height: 60px;
    }

    .heroButtons .heroButtonPrimary p {
        font-size: 15px;
    }

    .heroButtons .heroButtonSecondary {
        width: 120px;
        height: 60px;
    }

    .heroButtons .heroButtonSecondary p {
        font-size: 15px;
    }

}

@media only screen and (max-width: 420px) {

    .heroTitle {
        font-size: 36px;
    }

}

/* Hero Actions */

.actions {
    width: 100%;

    margin-top: 50px;
    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;
    align-items: end;
}

.downArrow {
    margin-left: 15px;

    cursor: pointer;
    
    animation: moveUpDown 2s ease-in-out infinite;
}

@keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
}

.downArrow img {
    height: 60px;
}

.badges {
    display: flex;
    align-items: end;
}

.badge {
    margin-right: 20px;
    
}

/* About */

.aboutContainer {
    width: 100%;
    height: 20vh;

    background-image: url('../../assets/map-background.png');

    background-color: #0f499d;
    color: white;

    font-family: Jost;

    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutContainer H1 {
    font-size: 40px;
}

.aboutContent {
    padding-top: 40px;
    padding-left: 30px;
}

.aboutContent p {
    font-family: 'Jost';
    color: white;

    font-size: 25px;
}

/* Content */

.pageContent p {
    width: 70%;
}

.pageContent {
    font-family: Jost;
    padding: 40px 20px 40px 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contentContainer {
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  grid-template-rows: 1fr 1fr;
}

.cell {
    padding: 20px;
    font-family: Jost;
    background-color: rgb(239, 239, 239);
    margin: 2px;
}

.cellNumber {
    font-size: 25px;
}

.cellHeader {
    font-size: 20px;
}


@media only screen and (max-width: 600px) {

    .contentContainer {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));;
    }


}

@media only screen and (max-width: 630px) {

    .aboutContainer h1 {
        font-size: 30px;
    }

}

@media only screen and (max-width: 450px) {

    .aboutContainer h1 {
        font-size: 25px;
    }


}

@media only screen and (max-width: 400px) {

    .aboutContainer h1 {
        font-size: 20px;
    }

    .contentContainer {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));;
    }


}




/* Contact Section */

.contactSection {
    width: 100%;
    height: 70vh;

    display: flex;
}

.contactSection .contactMap {
    width: 45%;

    display: flex;
    flex-direction: column;
}

.contactSection .contactMap .map {
    flex-grow: 1;
    background-color: bisque;
}

.contactSection .contactMap .footer {
    height: 50px;

    padding: 0% 2% 0% 2%;

    background-color: #323232;

    font-family: Jost;
    color: white;

    display: flex;
    justify-content: space-between;
    align-items: center;
}


.contactSection .contactForm {
    width: 55%;
    background-color: #0f499d;

    padding: 5%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.contactSection .contactForm .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactSection .contactForm .container form {
    width: 600px;
}

.contactSection .contactForm .container  label {
    font-family: Jost;
    color:  white;
    padding: 10px 0px 5px 0px;
}

.contactHeader {
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: white;
    font-size: 60px;
}

.contactInfo {
    text-align: center;
    color: white;
    font-family: Jost;
    font-size: 20px;
}

.formInput {
    padding: 0px 5px 0px 5px;

    display: flex;
    flex-direction: column;
}

.formInput input {
    padding: 5px;
}

.formInput select {
    padding: 5px;
}

.formInput textarea {
    padding: 5px;
}

.contactSubmit {
    width: 100%;
    height: 20px;

    font-size: 20px;
    font-family: Jost;
    background-color: #e62528;
    color: white;

    margin-top: 20px;

    padding: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1400px) {

    .contactHeader {
        font-size: 40px;
    }

    .contactInfo {
        font-size: 18px;
    }

}

@media only screen and (max-width: 1200px) {

    .contactSection .contactForm .container form {
        width: 450px;
    }

}

@media only screen and (max-width: 950px) {

    .contactSection {
        flex-direction: column-reverse;
        height: fit-content;
    }

    .contactSection .contactForm  {
        width: 100%;
    }

    .contactSection .contactForm .container form {
        width: 500px;
    }

    .contactSection .contactMap {
        width: 100%;
        height: 50vh;
    }

}

@media only screen and (max-width: 540px) {

    .contactSection .contactForm .container form {
        width: 400px;
    }


}

@media only screen and (max-width: 430px) {

    .contactSection .contactForm .container form {
        width: 300px;
        padding-bottom: 10px;
    }

    .formRow {
        flex-direction: column;
    }

    .formRow .formInput {
        width: 100% !important;
    }

}