.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(15, 73, 157);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .progress {
    background: white;
    justify-content: flex-start;
    border-radius: 10px;
    align-items: center;
    position: relative;
    display: flex;
    height: 2px;
    width: 300px;
  }
  
  .progress-value {
    animation: load 3s normal forwards;
    border-radius: 10px;
    background: rgb(248, 57, 60);
    height: 2px;
    width: 0;
  }
  
  @keyframes load {
    0% { width: 0; }
    100% { width: 100%; }
  }
  